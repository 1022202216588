<!--风险详情 lh 20211110-->
<template>
<div class="base-container">
    <div id="land-transaction">
        <div class="search-box">
            <div class="module-title" v-if="isLawRisk" :class="{'selected':activeName == 'sf'}" @click="titleTab('sf')">司法风险  {{numData.legalCount}}</div>
            <div class="module-title" v-if="isExecutorNewsRisk" :class="{'selected':activeName == 'yq'}" @click="titleTab('yq')">舆情风险  {{numData.opinionCount}}</div>
        </div>
        <div class="table_box" v-if="isLawRisk" v-show="activeName == 'sf'" >
            <el-table ref="table" :data="sftableData" border stripe height="400" :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                v-loading="loading"  @row-click="goCaseDetails" @sort-change="sfSortChange">
                <el-table-column type="index"  align="center" label="序号" width="80"></el-table-column>
                <el-table-column prop="caseName" label="标题" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="施工方" >
                    <template>
                        <span>{{ construction.constructionPartyName }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="caseReason"  label="案由"></el-table-column>
                <el-table-column sortable='custom' prop="docTypeDesc"  label="文书类型"></el-table-column>
                <el-table-column sortable='custom' prop="judgeDate"  align="right" label="日期"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template>
                        <el-button size="mini" type="text">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :total=sftotal
                    :current-page=sfpageNum>
                </el-pagination>
            </div>
        </div>
        <div class="table_box" v-if="isExecutorNewsRisk" v-show="activeName == 'yq'" >
            <el-table ref="table" :data="yqtableData" border stripe height="400" :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                v-loading="loading" @row-click="openYqDetail" @sort-change="yqSortChange">
                <el-table-column type="index"  align="center" label="序号" width="80"></el-table-column>
                <el-table-column prop="title"  label="标题" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="type" width="100" label="类别" ></el-table-column>
                <el-table-column sortable='custom' prop="city"  width="120" label="区域"></el-table-column>
                <el-table-column sortable='custom' prop="postTime"  align="right" width="160" label="舆情时间"></el-table-column>
                <el-table-column prop="content"  :show-overflow-tooltip="true" label="内容"></el-table-column>
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="yqHandleSizeChange"
                    @current-change="yqHandleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :total=yqtotal
                    :current-page=yqpageNum>
                </el-pagination>
            </div>
        </div>
        
        
    </div>
   <el-dialog :title="yqItem.title" :visible.sync="showDetail" @close="closeDialog" width="60%">
        <div class="yq_contant">
            <div>
                {{yqItem.content}}
            </div>
            <p class="yq_time">{{yqItem.postTime}}</p>
        </div>
    </el-dialog>
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        construction:{}
    },
  
    data() {
        return {
           
            sfpageNum: 1,
            sfpageSize: 10,
            sftableData: [],
            sftotal: 0,
            yqpageNum: 1,
            yqpageSize: 10,
            yqtableData: [],
            yqtotal: 0,
            yqItem: {},
            loading: false,
            activeName: 'sf',
            numData: {},
            showDetail: false,
            yqField: '',
            yqOrder: '',
            sfField: '',
            sfOrder: '',
            isLawRisk: true,
            isExecutorNewsRisk: true,
        }
    },
    created() {
        if(this.utils.isDisplay('executorNewsRisk:view')){
            this.isExecutorNewsRisk = true;
            this.activeName = 'yq';
        }else{
            this.isExecutorNewsRisk = false;
            this.activeName = 'sf';
        }
        if(this.utils.isDisplay('lawRisk:view')){
            this.isLawRisk = true;
            this.activeName = 'sf';
        }else{
            this.isLawRisk = false;
            this.activeName = 'yq';
        }
    },
    mounted() {
        this.getNum();
        this.getTableList();
        this.getYqTableList();
    },
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        titleTab(title){
            this.activeName = title;
            if(title=="sf"){
                this.getReport(2,'judicialRis','judicialRiskBtn')
            }else{
                this.getReport(2,'publicOpinion','publicOpinionBtn')
            }
        },
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'constructionDetail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        getNum() {
            this.axios({
                method: "get", 
                url: '/dms/getConstructionPartyRiskCount',
                 
                params: {
                    companyName: this.construction.constructionPartyName,
                    projectName: getStore('thisProject').proName
                },
            }).then((res) => {
                this.numData = res.data.data;
            }).catch((error) => {
                console.log(error)
            })
        },
        //查询talbe数据
        getTableList() {
            this.axios({
                method: "post", url: '/dms/getConstructionPartyJudgment',
                 
                data: {
                    constructionPartyName: this.construction.constructionPartyName,
                    pageNum: this.sfpageNum,
                    pageSize: this.sfpageSize,
                    field: this.sfField,
                    order: this.sfOrder,

                },
            }).then((res) => {
                this.sftableData = res.data.data.page.records;
                this.sftotal = res.data.data.page.total;
                // this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        getYqTableList() {
            this.axios({
                method: "post", url: '/dms/getConstructionPartyOpinion',
                 
                data: {
                    company: this.construction.constructionPartyName,
                    pageNum: this.yqpageNum,
                    pageSize: this.yqpageSize,
                    projectName: getStore('thisProject').proName,
                    projectId: getStore('thisProject').id,
                    city: '',
                    province: '',
                    field: this.yqField,
                    order: this.yqOrder,

                },
            }).then((res) => {
                //this.loading = false;
                this.yqtableData = res.data.data.records;
                this.yqtotal = res.data.data.total;
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
      
        //更改每页显示条数
        handleSizeChange(val) {
            this.sfpageSize = val;
            this.sfpageNum = 1;
            this.getTableList();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'judicialRis',val);
            this.sfpageNum = val;
            this.getTableList();
        },
        //更改每页显示条数
        yqHandleSizeChange(val) {
            this.getReport(2,'publicOpinion',val);
            this.yqpageSize = val;
            this.yqpageNum = 1;
            this.getYqTableList();
        },
        //更改页码
        yqHandleCurrentChange(val) {
            this.yqpageNum = val;
            this.getYqTableList();
        },
        goCaseDetails(row) {
            this.getReport(2,'judicialRis','judicialRiskDetail')
            this.addPath("风险详情");
            this.addMenuList("/judicial");
            setStore("currentMenu", "/judicial");
            this.$router.push({path: "/judicial"});
            setStore('judicial', row);
        },
        openYqDetail(row){
            this.getReport(2,'publicOpinion','publicOpinionDetail');
            this.yqItem = row;
            this.showDetail = true
        },
        //关闭舆情详情
        closeDialog(){
          this.getReport(2,'publicOpinion','publicOpinionDetailClose'); 
        },
        yqSortChange({ prop, order }) {
            this.getReport(2,'publicOpinion',prop+'Sort')
            this.yqpageNum = 1;
            this.yqField = prop;
            if(order == 'ascending'){
                this.yqOrder = 'asc'
            }else if(order == 'descending'){
                this.yqOrder = 'desc'
            }else{
                this.yqOrder = 'asc'
            }
            this.getYqTableList()
        },
        sfSortChange({ prop, order }) {
            this.getReport(2,'judicialRis',prop+'Sort')
            this.sfpageNum = 1;
            this.sfField = prop;
            if(order == 'ascending'){
                this.sfOrder = 'asc'
            }else if(order == 'descending'){
                this.sfOrder = 'desc'
            }else{
                this.sfOrder = 'asc'
            }
            this.getTableList()
        },
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 8px;
    height: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: #D8D8D8;
    border-radius: 4px;
}
.search-box {
    height: 38px;
    display: flex;
    border-bottom: 1px solid #EBEDF0;
    margin-bottom: 26px;
    div{
        margin-right: 24px;
        cursor: pointer;
    }
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.selected{
    color: #185BCE; 
}
/deep/tbody{
    cursor: pointer;
}
.yq_contant{
    border-top: 1px solid #EBEDF0;
    padding: 20px;
    height: 300px;
    overflow-y: scroll;
    line-height: 24px;
    text-indent: 2em;
}
/deep/.el-dialog__body{
    padding: 0;
}
.yq_time{
    text-align: right;
    margin-top: 20px;
}

</style>