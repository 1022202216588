<template>
    <div class="table_box">
        <el-form v-if="contractData.length>0||isListShow" :inline="true">
            <el-form-item label="合同名称：">
                <el-input v-model="contractName" placeholder="请输入合同名称"></el-input>
            </el-form-item>
            <el-form-item label="施工单位：">
                <el-input v-model="executor" placeholder="请输入施工单位"></el-input>
            </el-form-item>
            <el-form-item label="合同日期：">
                <el-date-picker class="date_select"
                    v-model="preDate"
                    @change="change"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>
            <div class="btn_box">
                <el-button
                class="search_btn"
                type="primary"
                size="small"
                @click="searchData"
                >查询</el-button
                >
                <el-button class="search_btn" size="small" @click="reset"
                >重置</el-button
                >
                
            </div>
            
        </el-form>
        <div class="button_box">
            <el-button v-if="contractData.length>0||isListShow" class="search_btn" type="primary" size="small" @click="openHt" >新增合同信息</el-button>
            <el-button v-if="contractData.length>0||isListShow" class="search_btn" type="danger" size="small" @click="deleteData" >批量删除合同</el-button>
            <el-button class="search_btn" type="success" size="small" @click="download" >下载excel模板</el-button>

            <el-upload
                class="upload-demo"
                :action="action"
                :headers="headerObj"
                name="file"
                :on-preview="handlePreview"
                :on-progress="handleProgress"
                :on-success="updataData"
                :on-exceed="handleExceed"
                :before-upload="beforeLoad"
                multiple
                :limit="100"
                :file-list="fileList">
                <el-button class="search_btn" type="primary" size="small">批量上传合同</el-button>
            </el-upload>
            

            <i v-if="contractData.length>0||isListShow" class="el-icon-setting" style="margin-left:9px" @click="getMoreCol"></i>
            <div class="col_more" v-show="isColMore">
                <p class="title">编辑列</p>
                <div class="reset" @click="colReset">重置</div>
                <el-table
                    ref="multipleTable"
                    :data="tagData"
                    :show-header="false"
                    @selection-change="handleSelectionChangeTag">
                    <el-table-column
                    type="selection"
                    width="55">
                    </el-table-column>
                    <el-table-column>
                    <template slot-scope="scope">{{ scope.row.name }}</template>
                    </el-table-column>
                
                </el-table>
            </div>
        </div>
        <el-table v-if="contractData.length>0||isListShow" ref="tableName" width="910" height="450" :data="contractData" border stripe @selection-change="tableChange" :header-cell-style="{background:'#F6F6F6',color:'#333333'}">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="contractNumber" label="合同编号" width="130"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="contractName" label="合同名称"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="executor" width="150" label="施工单位"></el-table-column>

            <el-table-column :show-overflow-tooltip="true" prop="contractDate" label="合同日期" width="100"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="contractCost" label="合同金额(元)" width="120"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="contractMainContent" label="合同主要内容" width="170"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="contractTypeName" label="合同类型" width="80"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" prop="constructionArea" label="建筑总面积(平方米)"></el-table-column>
            
            <el-table-column :show-overflow-tooltip="true" v-if="idArr.includes('xmdl')" prop="generaName" label="项目大类" width="100"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" v-if="idArr.includes('xmxl')" prop="subclassName" width="100" label="项目小类"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" v-if="idArr.includes('nsrsbh')" prop="executorTaxpayerNumber" width="120" label="施工单位纳税人识别号"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" v-if="idArr.includes('kgrq')" prop="startWorkingDate" width="100" label="开工日期"></el-table-column>
            <el-table-column :show-overflow-tooltip="true" v-if="idArr.includes('jgrq')" prop="completedDate" width="100" label="竣工日期"></el-table-column>
            <el-table-column label="操作" align="center" width="100">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" @click="modify(scope.row)">修改</el-button>
                    <el-button size="mini" type="text" class="delete" @click="deleteAssignment(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-if="contractData.length>0||isListShow" class="page-box">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              background
              layout="prev,pager,next,total,sizes,jumper"
              :page-sizes="[10, 50, 100]"
              :total="total"
              :current-page="pageNum"
            >
            </el-pagination>
        </div>
        <div v-if="!contractData.length>0&&!showLoading&&!isListShow" class="no_data">
            <div class="nullrelation"></div>
            <p>暂无工程合同数据，请通过下载模板来上传您的工程合同信息。</p>
        </div>
        <div v-if="showLoading" class="loading">
            
            <p><i class="el-icon-loading"></i>数据正在上传中，请您耐心等待。</p>
        </div>
        <el-drawer   
            :title="addTxt"
            :visible.sync="isAdd"
            direction="rtl"
            size="455px"
            style="position:absolute"
            z-index = "0"
            :before-close="handleClose">
            <div class="add_box">
                <table>
                    <tr>
                        <th>合同编号</th>
                        <td><el-input v-model="contractNumber" placeholder="请输入合同编号"></el-input></td>
                    </tr>
                    <tr>
                        <th><i>*</i>合同名称</th>
                        <td><el-input v-model="addContractName" placeholder="请输入合同名称"></el-input></td>
                    </tr>
                    <tr>
                        <th>合同日期</th>
                        <td>
                            <el-date-picker
                                v-model="contractDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期">
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <th><i>*</i>合同金额(元)</th>
                        <td><el-input type="number" v-model="contractCost" placeholder="请输入合同金额"></el-input></td>
                    </tr>
                    <tr>
                        <th>合同类型</th>
                        <td>
                            <el-select @change="typeSelectChange" v-model="contractTypeName" placeholder="请选择合同类型">
                                <el-option
                                v-for="(item,index) in proData"
                                :key="index"
                                :label="item.contractTypeName"
                                :value="item.contractTypeName">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th>建筑面积(平方米）</th>
                        <td><el-input type="number" v-model="constructionArea" placeholder="请输入建筑面积"></el-input></td>
                    </tr>
                    <tr>
                        <th>合同主要内容</th>
                        <td><el-input v-model="contractMainContent" placeholder="请输入您的合同主要内容"></el-input></td>
                    </tr>
                    <tr>
                        <th>项目大类</th>
                        <td>
                            <el-select @change="genSelectChange" v-model="generaName" placeholder="请选择项目大类">
                                <el-option
                                v-for="item in generaData"
                                :key="item.name"
                                :label="item.name"
                                :value="item.name">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th>项目小类</th>
                        <td>
                            <el-select @change="subSelectChange" v-model="subclassName" placeholder="请选择项目小类">
                                <el-option
                                v-for="(item,index) in subclassData"
                                :key="index"
                                :label="item.name"
                                :value="item.name">
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th><i>*</i>施工单位</th>
                        <td><el-input v-model="addExecutor" placeholder="请输入施工单位"></el-input></td>
                    </tr>
                    <tr>
                        <th>施工单位纳税人识别号</th>
                        <td><el-input v-model="executorTaxpayerNumber" placeholder="请输入施工单位纳税人识别号"></el-input></td>
                    </tr>
                    <tr>
                        <th>开工日期</th>
                        <td>
                            <el-date-picker
                                v-model="addStartDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择开工日期">
                            </el-date-picker>
                        </td>
                    </tr>
                    <tr>
                        <th>竣工日期</th>
                        <td>
                            <el-date-picker
                                v-model="addEndDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="请选择竣工日期">
                            </el-date-picker>
                        </td>
                    </tr>
                    
                </table>
                <el-button class="search_btn" type="primary" size="small" @click="save">保存</el-button>
                <el-button class="search_btn back" size="small" @click="addBack">取消</el-button>
            </div>
        </el-drawer>
    </div>

</template>

<script>

import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
import construction from "@/views/construction/construction.vue";
export default {
    props: {
    },
    components:{
        construction
    },
    data() {
        return {
            isListShow:true,
            fileList:[],
            contractData:[],
            executor:'',
            preDate:[],
            contractName:'',
            buildData:{},
            tableData:[],
            pageNum:1,
            pageSize:10,
            total:0,
            startDate:'',
            endDate:'',
            typeData:{
                1:'土地使用权证',
                3:'建设工程规划许可证',
                4:'建设施工许可证',
                6:'预售许可证信息',
                7:'不动产权证书'
            },
            isColMore:false,
            tagData:[
                {
                    name:'项目大类',
                    id:'xmdl'
                },
                {
                    name:'项目小类',
                    id:'xmxl'
                },
                
                {
                    name:'施工单位纳税人识别号',
                    id:'nsrsbh'
                },
                {
                    name:'开工日期',
                    id:'kgrq'
                },
                {
                    name:'竣工日期',
                    id:'jgrq'
                },
            ],
            idArr: [],
            headerObj:{
                Authorization:'',
            },
            deleteArr:[],
            isAdd:false,
            contractDate:'',  
            proData: [],       
            contractType:'',  
            contractTypeCode:'', 
            contractTypeName:'', 
            addStartDate:'',
            addEndDate:'',
            constructionArea:'',
            contractMainContent:'',
            addExecutor:'',
            executorTaxpayerNumber:'',
            addContractName:'',
            contractNumber:'',
            contractCost:'',
            generaName:'',
            subclassName:'',
            action:'',
            showLoading:false,
            generaData:[],
            subclassData:[],
            subData:{},
            addTxt:'新增合同信息',
            contId:'',
        }
    },
    created(){
        //获取合同列表
        this.getConstructionContract(true);
        this.headerObj.Authorization = 'Bearer ' + getLocalStore('token');
        this.action = '/api/dms/constructionContractTemplate/upload/' + getStore('thisProject').id+'/'+getStore('thisProject').taxpayerCode;
        //大类数据
        this.getFinancial();
        //合同类型
        this.getContractTypeDict();
    },
    mounted(){
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policyCaliber',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        handlePreview(file) {
        },
        
        handleProgress(event, file, fileList){
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 100 个文件`);
        },
        beforeLoad(file){
            this.showLoading = true;
        },
        //查询合同数据
        getConstructionContract(flag) {
            this.axios({
                method: "post", url: '/dms/pageConstructionContract',
                data: {
                    projectId: getStore('thisProject').id,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    contractName: this.contractName,
                    executor: this.executor,
                    contractDateStrart: this.startDate,
                    contractDateEnd: this.endDate,
                },
            }).then((res) => {
               if(res.data.data.records.length==0){
                   if(flag){
                       this.isListShow = false;
                   }else{
                       this.isListShow = true;
                   }
                   this.contractData = [];
                   this.total = 0;
               }else{
                    this.contractData = res.data.data.records;
                    this.total = res.data.data.total;
               }
            }).catch((error) => {
            })
        },
        searchData(){
            this.pageNum = 1;
            if(this.preDate==null){
                this.preDate = []
            }
            this.getConstructionContract(false);
        },
        reset(){
            this.contractName = '';
            this.executor = '';
            this.preDate = [];
            this.startDate = ''
            this.endDate = ''
     
        },
        change(val){
            if (val == null){
                this.preDate = [];
                val = [];
            } 
            if(this.preDate.length>0){
                this.startDate = this.preDate[0]
                this.endDate = this.preDate[1]
            }else{
                this.startDate = ''
                this.endDate = ''
            }
        },
        //切换条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getConstructionContract(false);
        },
        //切换页码
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getConstructionContract(false);
        },
        //编辑列
        getMoreCol(){
            this.isColMore = !this.isColMore;
        },
        colReset(){
             this.$refs.multipleTable.clearSelection();   
        },
        handleSelectionChangeTag(val){
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.id)
            });
            this.idArr = arr;
        },
        deleteContract(ids){
            this.axios({
                method: "post", url: '/dms/deleteContract',
                data: {
                    ids: ids,
                },
            }).then((res) => {
              if(res.data.status=='success'){
                  this.$message.success(res.data.msg);
                  this.pageNum = 1;
                  this.getConstructionContract(false);
              }else{
                  this.$message.error(res.data.msg);
              }
            }).catch((error) => {
            })
        },
        //新增合同
        openHt(){
            this.addTxt = '新增合同信息';
            this.isAdd = true
        },
        deleteData(){
            if(this.deleteArr.length==0){
                this.$message.warning('请先选择要删除的合同');
                return
            }
            this.$confirm('您是否确认删除这'+this.deleteArr.length+'条信息？', '删除合同信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var arr = []
                this.deleteArr.forEach(function(e){  
                    arr.push(e.id)
                });
                this.deleteContract(arr);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            
        },
        deleteAssignment(res){
            this.$confirm('您是否确认删除这条信息？', '删除合同信息', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                var arr = [];
                arr.push(res.id);
                this.deleteContract(arr);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
            

        },
        download(){
            this.axios({
                method: "get", 
                url: '/dms/constructionContractTemplate/download',
                responseType: 'blob'
            }).then((res) => {
               let data = res.data;
                if (!data) {
                        return
                }
                let url = window.URL.createObjectURL(new Blob([data]))
                let a = document.createElement('a')
                a.style.display = 'none'
                a.href = url
                a.setAttribute('download','合同模板.xls')
                document.body.appendChild(a)
                a.click() //执行下载
                window.URL.revokeObjectURL(a.href) //释放url
                document.body.removeChild(a) //释放标签
            }).catch((error) => {
                console.log(error)
            })
        },
        updataData(response, file, fileList){
            if(response.status=='success'){
                this.showLoading = false;
                this.pageNum = 1;
                this.getConstructionContract(false);
            }else{
                this.$message.warning(response.msg);
            }
        },
        tableChange(val){
            this.deleteArr = val;
        },
        handleClose(){
            this.isAdd = false;
        },
        typeSelectChange(val){
            var that = this;
            this.proData.forEach(function(e){
                if(e.contractTypeName == val ){
                    that.contractTypeCode = e.contractTypeCode;
                }
            })  
        },
        addBack(){
            this.isAdd = false;
        },
        save(){
            if(this.addContractName==''){
                this.$message.error('请输入合同名称');
                return false;
            }else if(this.contractCost==''){
                this.$message.error('请输入合同金额');
                return false;
            }else if(this.addExecutor==''){
                this.$message.error('请输入施工单位');
                return false;
            }else if(this.addStartDate>this.addEndDate){
                this.$message.error('竣工日期必须晚于开工日期');
                return false;
            }else{
                if(this.addTxt=='新增合同信息'){
                    this.addNewData();
                }else{
                    this.modifyData();
                }
            }
        },
        addNewData(){
            this.axios({
                method: "post", url: '/dms/addContract',
                data: {
                        projectId: getStore('thisProject').id,
                        contractNumber:this.contractNumber,
                        contractName: this.addContractName,
                        contractDate:this.contractDate,
                        contractCost:this.contractCost,
                        contractMainContent:this.contractMainContent,
                        contractTypeCode:this.contractTypeCode,
                        contractTypeName:this.contractTypeName,
                        constructionArea:this.constructionArea,
                        projectTypeName:'',//无项目类型
                        generaName:this.generaName,
                        subclassName:this.subclassName,
                        executorTaxpayerNumber:this.executorTaxpayerNumber,
                        executor: this.addExecutor,
                        startWorkingDate: this.addStartDate,
                        completedDate: this.addEndDate,
                    },
                }).then((res) => {
                   if(res.data.status=='success'){
                       this.$message.success(res.data.msg);
                       this.pageNum = 1;
                       this.getConstructionContract(false);
                       this.isAdd = false;
                       //还原数据
                       this.contractNumber = '';
                       this.addContractName = '';
                       this.contractDate = '';
                       this.contractCost = '';
                       this.contractMainContent = '';
                       this.contractTypeName = '';
                       this.constructionArea = '';
                       this.generaName = '';
                       this.subclassName = '';
                       this.executorTaxpayerNumber = '';
                       this.addExecutor = '';
                       this.addStartDate = '';
                       this.addEndDate = '';
                   }else{
                       this.$message.success(res.data.msg);
                   }
                }).catch((error) => {
                })
        },
        modifyData(){
            this.axios({
                method: "put", url: '/dms/updateContract/'+this.contId,
                data: {
                        contractNumber:this.contractNumber,
                        contractName: this.addContractName,
                        contractDate:this.contractDate,
                        contractCost:this.contractCost,
                        contractMainContent:this.contractMainContent,
                        contractTypeCode:this.contractTypeCode,
                        contractTypeName:this.contractTypeName,
                        constructionArea:this.constructionArea,
                        projectTypeName:'',//无项目类型
                        generaName:this.generaName,
                        subclassName:this.subclassName,
                        executorTaxpayerNumber:this.executorTaxpayerNumber,
                        executor: this.addExecutor,
                        startWorkingDate: this.addStartDate,
                        completedDate: this.addEndDate,
                    },
                }).then((res) => {
                   if(res.data.status=='success'){
                       this.$message.success(res.data.msg);
                       this.pageNum = 1;
                       this.getConstructionContract(false);
                       this.isAdd = false;
                       //还原数据
                       this.contractNumber = '';
                       this.addContractName = '';
                       this.contractDate = '';
                       this.contractCost = '';
                       this.contractMainContent = '';
                       this.contractTypeName = '';
                       this.constructionArea = '';
                       this.generaName = '';
                       this.subclassName = '';
                       this.executorTaxpayerNumber = '';
                       this.addExecutor = '';
                       this.addStartDate = '';
                       this.addEndDate = '';
                   }else{
                       this.$message.success(res.data.msg);
                   }
                }).catch((error) => {
                })
        },
        //获取大类数据
        getFinancial(){
            this.axios({
                method: "get", url: 'dms/contract/getFinancial',
               
            }).then((res) => {
              if(res.data.status=='success'){
                this.generaData = res.data.data.proBig;
                this.subData = res.data.data.proSub;
              }
            }).catch((error) => {
            })
        },
        //获取合同类型
        getContractTypeDict(){
            this.axios({
                method: "post", url: 'dms/getContractTypeDict',
               
            }).then((res) => {
              if(res.data.status=='success'){
                  this.proData = res.data.data;
              }
            }).catch((error) => {
            })
        },
        genSelectChange(val){
            var that = this;
            this.generaData.forEach(function(e){
                if(e.name==val){
                    that.subclassName = '';
                    that.subclassData = that.subData['code'+e.code]
                }   
            })
        },
        subSelectChange(){

        },
        delcommafy(num) {
            if ((num + "").trim() == "") {
            return "";
            }
            num = num.replace(/,/gi, "");
            return num;
        },
        modify(res){
            var contractTypeCode = '';
            this.proData.forEach(function(e){
                if(e.contractTypeName==res.contractTypeName){
                    contractTypeCode = e.contractTypeCode
                }
            })
            this.addTxt = '修改合同信息';
            this.contId = res.id;
            this.contractNumber = res.contractNumber||'';
            this.addContractName = res.contractName||'';
            this.contractDate = res.contractDate||'';
            this.contractCost = this.delcommafy(res.contractCost)||'';
            this.contractMainContent = res.contractMainContent||'';
            this.contractTypeName = res.contractTypeName||'';
            this.contractTypeCode = contractTypeCode||'';
            this.constructionArea = res.constructionArea||'';
            this.generaName = res.generaName||'';
            this.subclassName = res.subclassName||'';
            this.executorTaxpayerNumber = res.executorTaxpayerNumber||'';
            this.addExecutor = res.executor||'';
            this.addStartDate = res.startWorkingDate||'';
            this.addEndDate = res.completedDate||'';
            //大小类数据
            if(res.generaName){
                var that = this;
                this.generaData.forEach(function(e){
                    if(e.name==res.generaName){
                        that.subclassData = that.subData['code'+e.code]
                    }   
                })
            }else{
                this.subclassData = []
            }
            
            this.isAdd = true;

        }
    }
}
</script>

<style scoped  lang="less">
.table_box {
  padding: 16px 23px;
  min-height: 601px;
  border-top: 1px solid #EBEBEB;
  /deep/.el-form-item__content {
    width: 214px;
  }
  .button_box{
      text-align: right;
      margin-bottom: 17px;
      position: relative;
  }
  .el-form-item {
    margin-bottom: 12px !important;
    margin-right: 24px !important;
  }
  .rate{
      margin-right: 0 !important;
      /deep/.el-form-item__content{
        width: 57px;   
      }
      /deep/ .el-input__inner{
          width: 57px;
      }
  }
  /deep/.el-table .cell{
      padding-left: 3px !important;
      padding-right: 3px !important;
  }
  /deep/.el-table__body-wrapper{
      min-height: 366px !important;
  }
  .info{
      overflow: hidden;
      margin-bottom: 16px;
      div{
          float: left;
          font-size: 14px;
      }
      .total{
          font-weight: 600;
          margin-right: 16px;
      }
      .info_item{
          margin-right: 24px;
      }
  }
}
.btn_box{
    float: right;
    padding-top: 5px;
    i{
        cursor: pointer;
        margin-left: 24px;
    }
}
/deep/ th {
  padding: 4px;
}
/deep/ .el-table__cell {
  padding: 5px;
}
.el-input__inner.date_select{
    width: 240px;
}
.col_more{
    width: 214px;
    height: 252px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
    position: absolute;
    top: 50px;
    right: 5px;
    box-sizing: border-box;
    padding: 14px 0;
    z-index: 99;
    .reset{
        position: absolute;
        top: 17px;
        right: 17px;
        width: 28px;
        height: 20px;
        font-size: 14px;
        color: #185BCE;
        cursor: pointer;
    }
    /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
        border: none;
        cursor: pointer;
    }
    .el-table::before {
        height: 0;
    }
    .title{
        font-size: 16px;
        color: #323233;
        margin-bottom: 15px;
        padding-left: 11px;
        font-weight: 600;
        text-align: left;
    }
    
}
.el-icon-setting{
    cursor: pointer;
}
.delete {
    color: #d40000 !important;
}
.no_data{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    p{
        text-align: center;
        color: #909399;
        font-size: 14px;
        margin-bottom: 150px;
        .go_link{
            cursor: pointer;
            color: #185BCE;
        }
    }
}
.nullrelation {
    width: 250px;
    height: 156px;
    margin: 150px auto 0;
    background: url('../../assets/img/nullRelation.png') no-repeat;
}
.upload-demo{
    display: inline-block;
    margin-left: 10px;
}
/deep/.el-upload-list{
    display: none !important;
}
.add_box{
    table {
        width: 407px;
        margin: 0 auto;
        border-collapse: collapse;
    }
    /deep/.el-input__inner{
        height: 32px;
        line-height: 32px;
    }
    /deep/.el-input__icon{
        line-height: 32px;
    }
    .el-input{
        width: 100% !important;
    }
    .el-select{
        width: 100% !important;
    }

    th, td {
        padding: 1px 3px;
        border: 1px solid #EBEBEB;
        white-space: nowrap;
        font-weight: 500;
        text-align: left;
    }

    th {
        background: #F6F6F6;
        text-align: right;
        padding-right: 12px;
        i{
            color: #d40000;
            font-size:16px;
            margin-right: 4px;
        }
    }

    td {
        height: 1px;
        white-space: normal;

        #mapContainer {
            height: 100%;
            background: #ccc;
        }
    }
    .search_btn{
        width: 76px;
        position: absolute;
        bottom: 30px;
        right: 140px;
    }
    .search_btn.back{
        right: 240px;
    }
}
.loading{
    text-align: center;
    margin-top: 176px;
}
</style>