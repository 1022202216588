<!--历史业绩 lh 20211109-->
<template>
<div class="base-container">
    <!-- <div class="main-wrapper"> -->
        <div id="land-transaction">
            <div class="search-box">
                <div class="module-title">历史业绩</div>
                <el-form :inline="true" class="demo-form-inline" size="mini">
                    <el-form-item label="项目名称:">
                        <el-input v-model="projectName" placeholder="请输入项目名称关键词"></el-input>
                    </el-form-item>
                    <el-form-item label="中标时间:">
                        <el-date-picker
                            v-model="timeData"
                            @change="change"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd">>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search()">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table ref="table" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                    v-loading="loading" @sort-change="sortChange">
                <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
                <el-table-column prop="projectName" label="项目名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="amount" align="right" label="中标金额(万元)" ></el-table-column>
                <el-table-column sortable='custom' prop="bidDate" align="right" label="中标时间" ></el-table-column>
                <el-table-column prop="manager"  label="项目经理"></el-table-column>
                <el-table-column sortable='custom' prop="province" label="项目属地">
                    <template slot-scope="scope">
                        <span>{{ scope.row.province+scope.row.city }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="tendereeAndTel"  label="招标人及联系方式">
                    <template slot-scope="scope">
                        <span>{{ scope.row.bidCompany+ ':'+scope.row.phoneNumber }}</span>
                    </template>
                </el-table-column>
           
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
          
        </div>
    <!-- </div> -->
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
export default {
    props: {
        construction:{}
    },
    data() {
        return {
            projectName: '',
            tableData: [],
            loading: false,
            total: 0,
            pageNum: 1,
            pageSize: 10,
            showDetail:false,
            timeData: '',
            field: '',
            order: '',
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {

    },
    watch: {
        timeData(){
            if(this.timeData==null){
                this.timeData = '';
            }
        }
        
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'constructionDetail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableList() {
            if(this.timeData==null){
                this.timeData = []
            }

            this.axios({
                method: "post", url: '/dms/getConstructionPartyBidInfo',
                 
                data: {
                    amount: 0,
                    bidDate: "",
                    city: "",
                    company: this.construction.constructionPartyName,
                    endDate: this.timeData[1] || '',
                    field: this.field,
                    id: 0,
                    number: 0,
                    order: this.order,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectName: this.projectName,
                    province: "",
                    startDate: this.timeData[0] || '',
                    unit: ""
                },
            }).then((res) => {
                //this.loading = false;
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                //this.total = 200
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        //搜索页面归1
        search(){
            this.pageNum = 1;
            this.getReport(2,'history','searchBtn');
            this.getTableList();
        },
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableList();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'history',val);
            this.pageNum = val;
            this.getTableList();
        },
        change(val){
            if (val == null){
                this.timeData = '';
                val = '';
            } 
        },
        sortChange({ prop, order }) {
            this.getReport(2,'history',prop+'Sort');
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableList()
        },
      
    }
}
</script>

<style scoped lang="less">
    .search-box {
        height: 38px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #EBEDF0;
        margin-bottom: 26px;
    }
    .module-title {
        font-size: 16px;
        font-weight: bold;
    }

</style>