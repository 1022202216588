<template>
    <div class="base-container" id="scroll">
        <div class="main-wrapper" v-if="mainShow">
            <div class="header" :class="{'fixed':isFixed}">
                <div @click="goId('#sgflb')" class="more_list">施工方列表<i class="el-icon-sort"></i></div>
                <div class="hover_nav">
                    <div @click="goId('#jbxx')" class="nav" :class="{'active':navName == '#jbxx'}" v-if="utils.isDisplay('executorCompanyDetail:view')">基本信息</div>
                    <div @click="goId('#jyfw')" class="nav" :class="{'active':navName == '#jyfw'}">经营范围</div>
                    <div @click="goId('#gcht')" class="nav" :class="{'active':navName == '#gcht'}" v-if="utils.isDisplay('contract:overview')">工程合同</div>
                    <div @click="goId('#qyzz')" class="nav" :class="{'active':navName == '#qyzz'}" v-if="utils.isDisplay('companyQulification:view')">企业资质</div>
                    <div @click="goId('#lsyj')" class="nav" :class="{'active':navName == '#lsyj'}" v-if="utils.isDisplay('executorhistoricalProject:view')">历史业绩</div>
                    <div @click="goId('#glfx')" class="nav" :class="{'active':navName == '#glfx'}" v-if="utils.isDisplay('relatedRelationAnalysis:view')">关联分析</div>
                    <div @click="goId('#fxxx')" class="nav" :class="{'active':navName == '#fxxx'}" v-if="utils.isDisplay('lawRisk:view') || utils.isDisplay('executorNewsRisk:view') ">风险信息</div>
                </div>
                
            </div>
            <div class="item-box" id="jbxx" v-if="utils.isDisplay('executorCompanyDetail:view')">
                <div class="title">基本信息</div>
                <div class="gap-line"></div>
                <div class="banner">
                    <div class="left">
                        <div class="logo">
                            <div class="text">企业<br/>LOGO</div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="right">
                        <div class="info">
                            <div class="company">
                                <div class="name" v-text="construction.constructionPartyName"></div>
                                <!-- <div class="status">
                                    <div class="dot"></div>
                                    <div class="text">在业</div>
                                </div> -->
                            </div>
                            <!--                        <div class="tags">
                                                        <el-tag class="" color="#EDF4FF">高新技术企业</el-tag>
                                                        <el-tag class="" color="#EDF4FF">中小型企业</el-tag>
                                                        <el-tag class="" color="#FFF5ED">自身风险 52条</el-tag>
                                                        <el-tag class="" color="#FFF5ED">司法案件 36个</el-tag>
                                                        <el-tag class="" color="#FFEBEB">失信企业</el-tag>
                                                    </div>-->
                            <div class="info1">
                                <div class="info1-1">
                                    <div>法人代表：</div>
                                    <div v-text="companyBaseInfo.owner"></div>
                                </div>
                                <div class="info1-1">
                                    <div>注册资本：</div>
                                    <div v-text="companyBaseInfo.realRegistrationCapital"></div>
                                </div>
                                <div class="info1-1">
                                    <div>成立日期：</div>
                                    <div>{{ companyBaseInfo.registrationDate }}</div>
                                </div>
                                <div class="info1-1">
                                    <div>登记机关：</div>
                                    <div v-text="companyBaseInfo.registrationAuthority"></div>
                                    <!--                            <div class="dot" v-popover:popover></div>-->
                                </div>
                            </div>
                            <div class="info2">
                                <div class="info2-1">
                                    <div>电话：</div>
                                    <div v-text="companyBaseInfo.phoneNumber"></div>
                                </div>
                                <div class="info2-1">
                                    <div>邮箱：</div>
                                    <div v-text="companyBaseInfo.email"></div>
                                </div>
                                <div class="info2-1">
                                    <div>官网：</div>
                                    <div v-text="companyBaseInfo.website"></div>
                                </div>
                            </div>
                            <div class="info3">
                                <i class="el-icon-map-location"></i>
                                <div class="location" v-text="companyBaseInfo.registrationAddress"></div>
                                <div class="info2-1">
                                    <div>曾用名：</div>
                                    <div v-text="companyBaseInfo.companyFormerName || '-'"></div>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
            </div>
            
            
            <div class="item-box" id="jyfw">
                <div class="title">经营范围</div>
                <div class="gap-line"></div>
                <div class="describe" v-text="companyBaseInfo.businessScope"></div>
            </div>
            <div class="item-box" id="gcht" v-if="utils.isDisplay('contract:overview')">
                <engineeringContract v-if="isShow" :construction="construction"></engineeringContract>
            </div>
            <div class="item-box" id="qyzz" v-if="utils.isDisplay('companyQulification:view')">
                 <qualification v-if="isShow" :construction="construction"></qualification>
            </div>
            <div class="item-box" id="lsyj" v-if="utils.isDisplay('executorhistoricalProject:view')">
                 <performance v-if="isShow" :construction="construction"></performance>
            </div>
            <div class="item-box" id="glfx" v-if="utils.isDisplay('relatedRelationAnalysis:view')">
                 <association v-if="isShow" :construction="construction"></association>
            </div>
            <div class="item-box" id="fxxx" v-if="utils.isDisplay('lawRisk:view') || utils.isDisplay('executorNewsRisk:view') ">
                 <riskInformation v-if="isShow" :construction="construction"></riskInformation>
            </div>
        </div>
        <div class="no_data" v-else>
            <div class="nullrelation"></div>
            <p>暂无施工方数据，请前往工程合同列表通过上传合同来获取施工方数据。<span v-if="utils.isDisplay('contractUpload:view')" class="go_link" @click="goContract">点击前往</span></p>
        </div>
        <el-dialog title="" :visible.sync="showList" @close="closeDialog" width="1200px">
            <div class="yq_contant">
                <construction v-on:listenCostEvent="changeData" v-on:listenOpenEvent="goContract"></construction>
            </div>
        </el-dialog>
        <el-dialog title="工程合同列表" :visible.sync="showContract" @close="closeContract" width="1200px">
          <contract v-if="showContract"></contract>
        </el-dialog>
    </div>

</template>

<script>
import engineeringContract from "@/views/constructionDetails/engineeringContract.vue";
import performance from "@/views/constructionDetails/performance.vue";
import qualification from "@/views/constructionDetails/qualification.vue";
import association from "@/views/constructionDetails/association.vue";
import riskInformation from "@/views/constructionDetails/riskInformation.vue";
import contract from "@/views/constructionDetails/contract.vue";
import construction from "@/views/construction/construction.vue";
import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex"; //分析页通用table表格展示页
let scrollDom;
export default {
    components: {
        performance,
        engineeringContract,
        qualification,
        association,
        riskInformation,
        construction,
        contract,
    },
    data() {
        return {
            construction: {},
            navName: '#jbxx',
            isFixed: false,
            companyBaseInfo: {},
            showList:false,
            constructionPartyName:'',
            isShow: false,
            mainShow:true,
            showContract:false,
            contractData:[],
        }
    },
    created: function () {
        this.getReport(0,'','')

    },
    mounted: function () {
        // if (window.history && window.history.pushState) {
        //     history.pushState(null, null, document.URL);
        //     // 给 popstate 绑定一个方法 监听页面刷新
        //     // window.addEventListener('popstate', this.back, false);

            
        // }
        //获取合同数据
        this.getConstructionContract();
        //获取施工方列表数据
        this.getTableData();
        scrollDom = document.getElementById('scroll')
        setTimeout(() => {
            scrollDom.addEventListener('scroll',this.handleScroll)
        }, 1000);
    },
    destroyed() {
        this.getReport(1,'','');
        // window.removeEventListener('popstate', this.back, false);
        scrollDom.removeEventListener("scroll",this.handleScroll)
    },
    methods: {
        ...mapMutations(["reducePath", "addPath", "addMenuList", "reduceMenuList"]),
      
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'constructionDetail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询合同数据
        getConstructionContract() {
            this.axios({
                method: "post", url: '/dms/pageConstructionContract',
                data: {
                    projectId: getStore('thisProject').id,
                    pageNum: 1,
                    pageSize: 10,
                    contractName: '',
                    executor: '',
                    contractDateStrart: '',
                    contractDateEnd: '',
                },
            }).then((res) => {
               console.log(res.data.data.records)
               if(res.data.data.records.length==0){
                    this.mainShow = false;
               }else{
                    this.contractData = res.data.data.records;
                    this.mainShow = true;
               }
            }).catch((error) => {
                console.log(error)
            })
        },
         //查询talbe数据
        getTableData() {
            this.loading = true;
            this.axios({
                method: "post", url: '/dms/getContractTotalList',
                 
                data: {
                    constructionPartyName: this.constructionPartyName,
                    contractTypeCode: '',
                    pageNum: 1,
                    pageSize: 10,
                    projectId: getStore('thisProject').id,
                    projectName: getStore('thisProject').proName,
                    userId: getLocalStore('userinfo').id,
                    field: '',
                    order: '',
                },
            }).then((res) => {
                if(res.data.data.records.length>0){
                    this.construction = res.data.data.records[0];
                    this.loadCompanyBaseInfo();
                    this.isShow = true;
                    
                }else{
                    this.construction = {};
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
        changeData(res){
            this.isShow = false;
            this.showList = false;
            this.construction = res;
            this.loadCompanyBaseInfo();
            setTimeout(() => {
                this.isShow = true;
            }, 100);

        },
        closeDialog(){
            this.showList = false;
        },
        goId(id){
            
            if(id=='#jyfw'){
                this.getReport(2,'nav','natureBusiness')
            }else if(id=='#gcht'){
                this.getReport(2,'nav','engineeringContract')
            }else if(id=='#qyzz'){
                this.getReport(2,'nav','contractQulification')
            }else if(id=='#lsyj'){
                this.getReport(2,'nav','history')
            }else if(id=='#glfx'){
                this.getReport(2,'nav','contractRealtion')
            }else if(id=='#fxxx'){
                this.getReport(2,'nav','risk')
            }else if(id=='#jbxx'){
                //基本信息
                this.getReport(2,'nav','information')
            }
            if(id=='#sgflb'){
                this.showList = true;
            }else{
                this.navName = id;
                document.querySelector(id).scrollIntoView({block: "center", inline: "nearest", behavior: 'smooth'});
            }
        },
        handleScroll(){
            let mainHeight = scrollDom.scrollTop;
            if(mainHeight>10){
                this.isFixed = true;
            }else{
                this.isFixed = false;
            }
            if(document.getElementById('jbxx')){
                let jbxxScrollTop = document.getElementById('jbxx').offsetTop;
                if(jbxxScrollTop-mainHeight<300){
                    this.navName = '#jbxx'
                }
            }
            if(document.getElementById('jyfw')){
                let jyfwScrollTop = document.getElementById('jyfw').offsetTop;
                if(jyfwScrollTop-mainHeight<300){
                    this.navName = '#jyfw'
                }
            }
            if(document.getElementById('gcht')){
                let gchtScrollTop = document.getElementById('gcht').offsetTop;
                if(gchtScrollTop-mainHeight<300){
                    this.navName = '#gcht'
                }
            }
            if(document.getElementById('qyzz')){
                let qyzzScrollTop = document.getElementById('qyzz').offsetTop;
                if(qyzzScrollTop-mainHeight<300){
                    this.navName = '#qyzz'
                }
            }
            if(document.getElementById('lsyj')){
                let lsyjScrollTop = document.getElementById('lsyj').offsetTop;
                if(lsyjScrollTop-mainHeight<300){
                    this.navName = '#lsyj'
                }
            }
            if(document.getElementById('glfx')){
                let glfxScrollTop = document.getElementById('glfx').offsetTop;
                if(glfxScrollTop-mainHeight<300){
                    this.navName = '#glfx'
                }
            }
            if(document.getElementById('fxxx')){
                let fxxxScrollTop = document.getElementById('fxxx').offsetTop;
                if(fxxxScrollTop-mainHeight<300){
                    this.navName = '#fxxx'
                }
            }
        },
       
        loadCompanyBaseInfo() {
            this.axios.request({
                loading: true,
                method: "post",
                url: 'dms/getBusinessLicenseByNumber',
                 
                params: {
                    companyName: this.construction.constructionPartyName, 
                    taxpayerCode: this.construction.constructionPartyTaxpayerNum
                }
            }).then((res) => {
                if (res.data.status == "failed") {
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                } else if (res.data.status == "success") {
                    if (res.data.data) {
                        this.companyBaseInfo = res.data.data;
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }
            }).catch((error) => {
                console.log(error);
            })
        },
        goContract(){
            this.showContract = true
        },
        closeContract(){
            this.showContract = false
        },
      

    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}
.no_data{
    width: 1200px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    p{
        text-align: center;
        color: #909399;
        font-size: 14px;
        .go_link{
            cursor: pointer;
            color: #185BCE;
        }
    }
}
.nullrelation {
    width: 250px;
    height: 156px;
    margin: 0 auto;
    background: url('../../assets/img/nullRelation.png') no-repeat;
    margin-top: 200px;
}
.more_list{
    width: 132px;
    height: 36px;
    background: #185BCE;
    border-radius: 2px 0px 0px 2px;
    float: left;
    text-align: center;
    color: #ffffff;
    line-height: 36px;
    cursor: pointer;
}
.banner {
    display: flex;
    width: 100%;
    background: #FFFFFF;
    border-radius: 2px;

    .left {
        position: relative;
        width: 158px;
        height: 218px;

        .logo {
            position: absolute;
            width: 78px;
            height: 78px;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            color: #000C17;
            font-size: 16px;
            background-color: #F2F3F5;
            border-radius: 2px;
            //opacity: 0.1;
            .text {
                position: absolute;
                width: 36px;
                height: 36px;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }

        .line {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 178px;
            background-color: #F2F3F5;
        }
    }


    .right {
        position: relative;
        width: calc(100% - 160px);
        padding: 24px 40px;

        .info {
            width: 100%;
            height: auto;

            .company {
                display: flex;
                margin-bottom: 8px;
                width: 100%;
                height: 26px;
                position: relative;
                .back{
                    position: absolute;
                    right: 0;
                    cursor: pointer;
                    color: #185BCE;
                }

                .name {
                    color: #323233;
                    font-size: 18px;
                    height: 26px;
                    line-height: 26px;
                }

                .status {
                    display: flex;
                    align-items: center;
                    margin-left: 16px;

                    .dot {
                        width: 10px;
                        height: 10px;
                        margin-right: 6px;
                        background: #2DA641;
                        border-radius: 10px;
                        border: 2px solid #D8F4DC;
                    }

                    .text {
                        width: 28px;
                        height: 20px;
                        line-height: 20px;
                        font-weight: 400;
                        color: #2DA641;
                    }


                }
            }

            .tags {
                .el-tag {
                    margin-right: 8px;
                    border: none;
                    height: 20px;
                    line-height: 20px;
                    width: auto;
                }

                :nth-child(1), :nth-child(2) {
                    color: #185BCE
                }

                :nth-child(3), :nth-child(4) {
                    color: #ED6A0C
                }

                :nth-child(5) {
                    color: #D40000
                }
            }

            .info1 {
                display: flex;
                margin-top: 25px;
                width: 100%;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                .info1-1 {
                    display: inline-flex;
                    margin-right: 24px;

                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                    }

                }

                .dot {
                    display: inline-flex;
                    justify-content: space-around;
                    margin: auto 5px;
                    width: 8px;
                    height: 8px;
                    background: #D40000;
                    border-radius: 8px;
                    cursor: pointer;
                }
            }

            .info2 {
                margin-top: 16px;
                display: flex;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                div {
                    display: inline-flex;

                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                        margin-right: 24px;
                    }

                }
            }

            .info3 {
                margin-top: 16px;
                display: flex;
                height: 20px;
                line-height: 20px;
                font-size: 14px;

                i {
                    margin-right: 8px;
                    padding-top: 2.5px;
                }

                .location {
                    color: #646566;
                }
                .info2-1 {
                    display: inline-flex;
                    margin-left: 24px;
                    :nth-child(1) {
                        color: #969799;
                    }

                    :nth-child(2) {
                        color: #646566;
                        margin-right: 24px;
                    }

                }
            }

            /deep/ .other-data-sources {
                .datatype {
                    display: inline-block;

                    :nth-child(1) {
                        display: inline-block;
                        width: 70px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #969799;
                        line-height: 20px;
                    }

                    :nth-child(2) {
                        display: inline-block;
                        width: 28px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #646566;
                        line-height: 20px;
                    }
                }

                /deep/ .enterprise-check {
                    :nth-child(1) {
                        width: 56px;
                        height: 20px;
                        margin-left: 14px;
                    }

                }

                /deep/ .other-feedback {
                    margin-top: 16px;
                    width: 56px;
                    height: 20px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #185BCE;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

.header{
    width: 1200px;
    height: 36px;
    overflow: hidden;
    margin-bottom: 24px;
    z-index: 9;
    .hover_nav{
        overflow: hidden;
        height: 36px;
        border-right: 1px solid #DEE0E3;
        float: left;
    }
    .nav{
        float: left;
        width: 132px;
        border: 1px solid #DEE0E3;
        box-sizing: border-box;
        text-align: center;
        line-height: 34px;
        cursor: pointer;
        background: #ffffff;
        border-right: none;

    }
    .nav.active{
        color: #185BCE;
    }
}
.fixed{
    position: fixed;
    top: 160px;
}
.item-box {
    background: #FFFFFF;
    padding: 15px;
    box-sizing: border-box;
    margin-top: 15px;

    .title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;

        i {
            display: inline-block;
            position: absolute;
            margin: 3px 10px;
            cursor: pointer;
            width: 16px;
            height: 16px;
            background: url("../../assets/img/question.png") center no-repeat;
        }
    }

    .gap-line {
        height: 1px;
        background: #eceaea;
        margin-bottom: 15px;
    }
}

.describe {
    font-size: 14px;
    line-height: 1.5;
    padding: 0 15px;
}

/deep/ .el-tabs--card > .el-tabs__header {
    border-bottom: none;
}

/deep/ .el-tabs__item.is-active {
    color: #FFFFFF;
    background-color: #185BCE;
}

/deep/ .el-tabs__content {
    width: 100%;
    position: relative;
}

/deep/ .el-tabs__item {
    width: 44px;
    height: 26px;
    line-height: 26px;
    padding: 0 7px !important;
    background: rgba(50, 50, 51, 0.06);
    border-radius: 2px 0 0 2px;
    border: 1px solid rgba(50, 50, 51, 0.06);
}
/deep/.el-dialog__body{
    padding: 0 !important;
}

</style>