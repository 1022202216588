<!--企业资质 lh 20211109-->
<template>
<div class="base-container">
    <!-- <div class="main-wrapper"> -->
        <div id="land-transaction">
            <div class="search-box">
                <div class="module-title">企业资质</div>
            </div>
            <el-table ref="table" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                    @sort-change="sortChange" v-loading="loading">
                <el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
                <el-table-column sortable='custom' prop="qualificationScope" label="资质类型" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="qualificationNo" label="证书编号" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="qualificationName"  label="资质名称" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="startDate"  align="right" label="发证日期" width="120"></el-table-column>
                <el-table-column sortable='custom' prop="endDate"  align="right" label="有效期" width="120"></el-table-column>
                <el-table-column prop="issuingAuthority"  label="发证机关"></el-table-column>
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
        </div>
    <!-- </div> -->
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
export default {
    props: {
        construction:{}
    },
  
    data() {
        return {
            loading: false,
            tableData: [],
            pageNum: 1,
            pageSize: 10,
            total: 0,
            field: '',
            order: '',
            
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {
    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'constructionDetail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableList() {
            this.axios({
                method: "post", 
                url: '/dms/getConstructionPartyQulification',
                data: {
                    companyName: this.construction.constructionPartyName, 
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    field: this.field,
                    order: this.order,
                }
            }).then((res) => {
                this.tableData = res.data.data.records;
                this.total = res.data.data.total;
                
               
              
            }).catch((error) => {
                console.log(error)
            })
        },
     
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableList();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'contractQulification',val);
            this.pageNum = val;
            this.getTableList();
        },
        sortChange({ prop, order }) {
            this.getReport(2,'contractQulification',prop+'Sort');
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableList()
        },

    }
}
</script>

<style scoped lang="less">
#land-transaction{
    min-height: 400px;
}
.search-box {
    height: 38px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EBEDF0;
    margin-bottom: 26px;
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}

</style>