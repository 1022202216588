<!--工程合同 lh 20211109-->
<template>
<div class="base-container">
    <!-- <div class="main-wrapper"> -->
        <div id="land-transaction">
            <div class="search-box">
                <div class="module-title">工程合同</div>
            </div>
            <p class="allAmount">合同金额合计（元）：{{allAmount}}</p>
            <el-table ref="table" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                    v-loading="loading" @sort-change="sortChange">
                <el-table-column prop="serialNumber" align="center" label="序号" width="80"></el-table-column>
                <el-table-column sortable='custom' prop="contractType" label="合同类型" width="120"></el-table-column>
                <el-table-column sortable='custom' prop="contractNum" label="合同编号" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="contractDate" align="right" label="合同日期" ></el-table-column>
                <el-table-column sortable='custom' prop="contractAmount"  align="right" label="合同金额（元）" width="150"></el-table-column>
                <el-table-column prop="contractContent"  width="200" label="合同主要内容" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column sortable='custom' prop="startDate"  align="right" label="开工日期"></el-table-column>
                <el-table-column sortable='custom' prop="completedDate"   align="right" label="竣工日期"></el-table-column>
                <el-table-column sortable='custom' prop="buildingArea"  align="right" label="建筑总面积（平方米）"></el-table-column>
           
            </el-table>
            <div class="page-box">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    background
                    layout="prev,pager,next,total,sizes,jumper"
                    :page-sizes="[10, 20, 50, 100]"
                    :total=total
                    :current-page=pageNum>
                </el-pagination>
            </div>
          
        </div>
    <!-- </div> -->
</div>
    
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
export default {
    props: {
        construction:{}
    },
  
    data() {
        return {
            pageNum: 1,
            pageSize: 10,
            tableData: [],
            loading: false,
            total: 0,
            proInfo: {},
            showDetail:false,
            field: '',
            order: '',
            allAmount:0,
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {

    },
    methods: {
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'constructionDetail',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableList() {
            this.axios({
                method: "post", url: '/dms/getConstructionPartyByName',
                 
                data: {
                    constructionPartyName: this.construction.constructionPartyName,
                    contractTypeCode: '',
                    projectId: getStore('thisProject').id,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectName: getStore('thisProject').proName,
                    field: this.field,
                    order: this.order,
                },
            }).then((res) => {
                //this.loading = false;
                this.tableData = res.data.data.page.records;
                this.total = res.data.data.page.total;
                this.allAmount = res.data.data.allAmount
                //this.total = 200
                this.loading = false;
            }).catch((error) => {
                console.log(error)
            })
        },
    
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableList();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'engineeringContract',val);
            this.pageNum = val;
            this.getTableList();
        },
        sortChange({ prop, order }) {
            this.getReport(2,'engineeringContract',prop+'Sort');
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableList()
        },

    }
}
</script>

<style scoped lang="less">
#land-transaction{
    min-height: 400px;
}
.search-box {
    height: 38px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EBEDF0;
    margin-bottom: 17px;
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.allAmount{
    margin-bottom: 17px;
    color: #333333;
}

</style>