<!--关联分析 lh 20211109-->
<template>
<div class="base-container">
    <!-- <div class="main-wrapper"> -->
        <div id="land-transaction">
            <div class="search-box">
                <div class="module-title">关联分析</div>
            </div>
            <el-table ref="table" :data="tableData" border stripe :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                    v-loading="loading">
                <el-table-column type="index" label="序号" width="80"></el-table-column>
                <el-table-column prop="companyName" label="建设单位" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column prop="otherCompanyName" label="施工单位" ></el-table-column>
                <el-table-column prop="contractCost"  label="合同金额（万元）"></el-table-column>
                <el-table-column prop="contractCostTotal"  label="合同总金额（万元）"></el-table-column>
                <el-table-column prop="costRate"  label="占比"></el-table-column>
                <el-table-column prop="description"  label="详情"></el-table-column>
           
            </el-table>
      
          
        </div>
    <!-- </div> -->
</div>
    
</template>

<script>
import {getStore} from '@/util/store'
export default {
    props: {
        construction:{}
    },
    data() {
        return {
            tableData: [],
            loading: false,
            showDetail:false
        }
    },
    created() {
        
    },
    mounted() {
        this.getTableList();
    },
    watch:{

    },
    methods: {
        //查询talbe数据
        getTableList() {
            this.axios({
                method: "get", url: '/dms/getConstructionPartyRelation',
                 
                params: {
                    buildingName: this.construction.developerName,
                    constructionPartyName: this.construction.constructionPartyName,
                    projectId: this.construction.projectId,
                },
            }).then((res) => {
                if(res.data.data.companyName){
                    this.tableData.push(res.data.data);
                }
                
            }).catch((error) => {
                console.log(error)
            })
        },
   


    }
}
</script>

<style scoped lang="less">
    .search-box {
        height: 38px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #EBEDF0;
        margin-bottom: 26px;
    }
    .module-title {
        font-size: 16px;
        font-weight: bold;
    }

</style>