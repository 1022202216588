<!--施工方分析 lh 20211108-->
<template>
    <div class="base-container">
        <el-main>
            <div>
                <div class="top_title">施工方列表</div>
                <div class="input_box">
                    <p>施工企业：</p>
                    <el-input class="search-content" prefix-icon="el-icon-search" size="small" v-model="constructionPartyName" placeholder="点击输入企业名称" ></el-input>
                    <el-button class="search_btn" type="primary" size="small" @click="searchClick()">搜索</el-button>
                    <el-button class="search_btn" size="small" @click="reset()">重置</el-button>
                    <el-button class="search_btn open" type="primary" size="small" @click="openHt()" v-if="utils.isDisplay('contractUpload:view')">工程合同列表</el-button>

                </div>
            </div>
            <div class="chart_box">
                <div class="table-box">
                    <el-table ref="table"  style="width: 100%" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}" @sort-change="sortChange" v-loading="loading" @row-click="openDetails">
                        <el-table-column prop="serialNumber" label="序号" align="center" width="50"></el-table-column>
                        <el-table-column sortable='custom' prop="constructionPartyName" align="left" label="施工单位" width="190" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column sortable='custom' prop="contractAllAmount" align="right" label="合同总金额(元)" width="135" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column sortable='custom' prop="contractAllArea" align="right" label="建筑总面积(平方米)" width="165"></el-table-column>
                        <el-table-column sortable='custom' prop="contractQulification" align="left" label="企业资质" width="95"></el-table-column>
                        <el-table-column sortable='custom' prop="contractBidInfoNum" align="right" label="历史业绩数量" width="125"></el-table-column>
                        <el-table-column sortable='custom' prop="contractRealtion" align="left" label="关联分析" width="95"></el-table-column>
                        <el-table-column sortable='custom' prop="opinionNum" align="right" label="舆情风险数量"></el-table-column>
                        <el-table-column sortable='custom' prop="judgmentNum" align="right" label="法律风险数量"></el-table-column>
                        <el-table-column label="操作" align="center" width="60">
                            <template>
                                <el-button size="mini" type="text">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="page-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        layout="prev,pager,next,total,sizes,jumper"
                        :page-sizes="[10]"
                        :total="total"
                        :current-page="pageNum">
                    </el-pagination>
                </div>
            </div>
        </el-main>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations} from "vuex";

export default {
    components: {
    },
    data() {
        return {
          
            tableData: [],
            loading: true,
            total: 0,
            showDetail: true,
            constructionPartyName: '',
            pageNum: 1,
            pageSize: 10,
            projectId: getStore('thisProject').id,
            field: '',
            order: '',
      
        }
    },
    created() {
        this.getReport(0,'','')
    },
    destroyed(){
        this.getReport(1,'','')
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'construction',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //查询talbe数据
        getTableData() {
            this.loading = true;
            this.axios({
                method: "post", url: '/dms/getContractTotalList',
                 
                data: {
                    constructionPartyName: this.constructionPartyName,
                    contractTypeCode: '',
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    projectId: this.projectId,
                    projectName: getStore('thisProject').proName,
                    userId: getLocalStore('userinfo').id,
                    field: this.field,
                    order: this.order,
                },
            }).then((res) => {
                if(res.data.data.records.length>0){
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }else{
                    this.tableData = [];
                    this.total = 0;
                }
                this.loading = false;
                
            }).catch((error) => {
                console.log(error)
            })
        },
        searchClick(){
            this.pageNum = 1;
            this.getReport(2,'constructionList','searchBtn')
            this.getTableData();
        },
    
        //更改每页显示条数
        handleSizeChange(val) {
            this.pageSize = val;
            this.pageNum = 1;
            this.getTableData();
        },
        //更改页码
        handleCurrentChange(val) {
            this.getReport(2,'constructionList',val)
            this.pageNum = val;
            this.getTableData();
        },
        //查看详情
        openDetails(row) {
            this.getReport(2,'constructionList','detailBtn')
            // this.addPath(row.constructionPartyName);
            // this.addMenuList("/constructionDetails");
            // setStore("currentMenu", "/constructionDetails");
            setStore("construction", row);
            this.$emit("listenCostEvent",row)
            // setStore("navitem", "constructionDetails");
            // this.$router.push({path: "/constructionDetails"});
        },
        handleSelectionChangeTag(val){
            let arr = [];
            val.forEach(function(e){  
                arr.push(e.id)
            });
            this.idArr = arr;

        },
        getMoreCol(){
            this.isColMore = !this.isColMore;
        },
        reset(){
            this.constructionPartyName = '';
            this.getReport(2,'constructionList','resetBtn')
        },
        sortDevName(str1, str2) {
            str1 = str1.constructionPartyName;
            str2 = str2.constructionPartyName;
            let res = 0
            for (let i = 0; ;i++) {
            if (!str1[i] || !str2[i]) {
                res = str1.length - str2.length
                break
            }
            const char1 = str1[i]
            const char1Type = this.getChartType(char1)
            const char2 = str2[i]
            const char2Type = this.getChartType(char2)
            // 类型相同的逐个比较字符
            if (char1Type[0] === char2Type[0]) {
                if (char1 === char2) {
                continue
                } else {
                if (char1Type[0] === 'zh') {
                    res = char1.localeCompare(char2)
                } else if (char1Type[0] === 'en') {
                    res = char1.charCodeAt(0) - char2.charCodeAt(0)
                } else {
                    res = char1 - char2
                }
                break
                }
            } else {
            // 类型不同的，直接用返回的数字相减
                res = char1Type[1] - char2Type[1]
                break
            }
            }
            return res
        },
        getChartType(char) {
            // 数字可按照排序的要求进行自定义，我这边产品的要求是
            // 数字（0->9）->大写字母（A->Z）->小写字母（a->z）->中文拼音（a->z）
            if (/^[\u4e00-\u9fa5]$/.test(char)) {
                return ['zh', 300]
            }
            if (/^[a-zA-Z]$/.test(char)) {
                return ['en', 200]
            }
            if (/^[0-9]$/.test(char)) {
                return ['number', 100]
            }
            return ['others', 999]
        },
        sortChange({ prop, order }) {
            this.getReport(2,'constructionList',prop+'Sort')
            this.pageNum = 1;
            this.field = prop;
            if(order == 'ascending'){
                this.order = 'asc'
            }else if(order == 'descending'){
                this.order = 'desc'
            }else{
                this.order = 'asc'
            }
            this.getTableData()
        },
        openHt(){
            this.$emit("listenOpenEvent")
        }

    }
}
</script>

<style scoped lang="less">
.el-main{
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 0 !important;
    background-color: #fff;
    .top_title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        box-sizing: border-box;
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        border-bottom: 1px solid #EBEBEB;
    }
}
.input_box{
    width: 100%;
    height: 32px;
    margin-bottom: 16px;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    position: relative;
    >p{
        float: left;
        line-height: 32px;
    }
    .el-input{
        float: left;
        width: 200px;
        margin-right: 10px;
    }
    .el-select{
        float: left;
    }
    /deep/.el-input__inner{
        width: 200px;
        height: 32px !important;
        line-height: 32px !important;
    }
    .el-button{
        float: left;
        margin-left: 12px;
    }
    .push_chart{
        margin-left: 0 !important;
    }
    .more_search{
        color: #185BCE;
        cursor: pointer;
    }
    .right{
        float: right;
        height: 100%;
        line-height: 32px;
        color: #185BCE;
        >p{
            float: left;
            margin-right: 16px;
            cursor: pointer;
        }
        >i{
            cursor: pointer;
        }
    }
    .col_more{
        width: 214px;
        height: 252px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(200, 201, 204, 0.5);
        position: absolute;
        top: 50px;
        right: 5px;
        box-sizing: border-box;
        padding: 14px 0;
        z-index: 99;
        overflow-y: scroll;
        .reset{
            position: absolute;
            top: 17px;
            right: 17px;
            width: 28px;
            height: 20px;
            font-size: 14px;
            color: #185BCE;
            cursor: pointer;
        }
        /deep/.el-table td,.el-table th.is-leaf,.el-table--border,.el-table--group {
            border: none;
            cursor: pointer;
        }
        .el-table::before {
            height: 0;
        }
        .title{
            font-size: 16px;
            color: #323233;
            margin-bottom: 15px;
            padding-left: 11px;
            font-weight: 600;
        }
        
    }
}
.module-title {
    font-size: 16px;
    font-weight: bold;
}
.search-box {
    display: flex;
    justify-content: space-between;
}
.chart_box{
    padding: 20px;
}
.table-box {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.page-box {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
/deep/.el-dialog__body{
    padding: 0;
}
/deep/tbody{
    cursor: pointer;
}
/deep/.el-table .caret-wrapper{
    width: 16px;
}
/deep/.el-table .el-table__cell{
    padding: 5px 0;
}
.open{
    position: absolute;
    right: 24px;
}
</style>